import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import axios from "axios";
import BaseComponent from "../../../Common/BaseComponent";
import TagManager from "react-gtm-module";
import { getBgColor } from "../../../../helper/Color";

class FeatureSubscriptionBundaSubscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        pageId: "",
        dbName: "",
        emailAddress: "",
      },
      message: "",
      error: false,
    };
  }

  componentDidMount() {
    let contextInfo = window.document.getElementsByName("ContextItem")[0];
    let pageId = contextInfo.value;
    let dbName = contextInfo.dataset.targetDb;

    this.setState({
      ...this.state,
      formData: {
        pageId: pageId,
        dbName: dbName,
        emailAddress: "",
      },
    });
  }

  subscribe = async () => {
    const url = `/api/sitecore/subscription/subscribe`;
    let data = { ...this.state.formData };

    if (this.state.formData.emailAddress !== "") {
      if (!this.validateFormat(this.state.formData.emailAddress)) {
        this.setState({
          ...this.state,
          message: "Please enter a valid email.",
          error: true,
        });
      } else {
        let response = await axios({
          method: "POST",
          url,
          data: data,
        });

        if (response && response.data) {
          if (response.data.IsSuccessful) {
            TagManager.dataLayer({
              dataLayer: {
                event: "subscribe",
              },
              dataLayerName: "dataLayer",
            });
            this.setState({
              ...this.state,
              message: response.data.Message,
              error: false,
            });
          } else {
            this.setState({
              ...this.state,
              message: response.data.Message,
              error: false,
            });
          }
        }
      }
    } else {
      this.setState({
        ...this.state,
        message: "Please enter a valid email.",
        error: true,
      });
    }
  };

  handleEmailChange = (e) => {
    let message = "";
    let error = false;

    if (this.state.error && !this.validateFormat(e.target.value)) {
      message = "Please enter a valid email.";
      error = true;
    }

    this.setState({
      ...this.state,
      formData: {
        ...this.state.formData,
        emailAddress: e.target.value,
      },
      message: message,
      error: error,
    });
  };

  validateFormat = (value) => {
    let re = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$");
    return re.test(String(value).toLowerCase());
  };


  render() {
    const {
      Description: subtitle = null,
      // BackgroundColor: backgroundColor = null,
    } = this.props.fields || {};

    // TODO: Remove if the BE implemented the "BackgroundColor" field
    let backgroundColor = {
      "fields": {
        "Colour": {
          "value": "White"
        },
        "Hex": {
          "value": "#ffffff"
        }
      }
    }

    return (
      <BaseComponent margin="standard">
        <div className={`bunda-subscribe ${getBgColor(backgroundColor)}`}>
          <div className="container">
            <div className="bunda-subscribe__subtitle">
              <Text field={subtitle} />
            </div>

            <div className="bunda-subscribe__container">
              <div className="bunda-subscribe__email">
                <input
                  type="email"
                  id="email-field"
                  placeholder="Email address"
                  className={
                    "form-control" +
                    (this.state.message ? " box-message" : "") +
                    (this.state.error ? " box-error" : "")
                  }
                  onChange={(e) => {
                    this.handleEmailChange(e);
                  }}
                />
                {this.state.message !== "" && (
                  <div
                    className={
                      "text " +
                      (this.state.message ? "text-message" : "") +
                      (this.state.error ? " text-error" : "")
                    }
                  >
                    {this.state.message}
                  </div>
                )}
              </div>
              <button
                className="btn btn-subscribe btn--large btn--light-primary"
                onClick={() => {
                  this.subscribe();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureSubscriptionBundaSubscribe;
