import React from "react";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import Flickity from "react-flickity-component";
import { getBgColor } from "../../../../helper/Color";

const FeatureGenericContentBundaImageCarousel = (props) => {
  const {
    Title: title = null,
    Subtext: subtitle = null,
    Images: images = null,
    Features: features = null,
    BackgroundColor: backgroundColor = null,
  } = props.fields;

  return (
    <div
      className={`bunda-image-carousel-wrapper ${getBgColor(backgroundColor)}`}
    >
      <div className="container">
        <div className="module-title">
          <Text field={title} />
        </div>
        <div className="module-subtitle">
          <RichText field={subtitle} />
        </div>
      </div>
      {images && images.length && (
        <Flickity className={"carousel"} reloadOnUpdate static>
          {images.map((item, index) => {
            const { ImageUrl: url, ImageAltText: alt } = item.fields;
            if (url && url.value) {
              return (
                <img
                  src={url.value}
                  className="carousel-cell"
                  key={`carousel${index}`}
                  alt={alt && alt.value ? alt.value : "carousel_image"}
                />
              );
            } else return null;
          })}
        </Flickity>
      )}
      {features && features.length ? (
        <div className="container">
          <div className="features-wrapper">
            <div className="section-header">Features</div>
            <div className="row">
              {features.map((item, index) => {
                const {
                  IconClass: icon = null,
                  Name: name = null,
                } = item.fields;
                if (icon && icon.value) {
                  return (
                    <div className="col-12 col-md-2" key={`feature${index}`}>
                      <div className="feature">
                        <div className="icon-container">
                          <i className={`icon icon-${icon.value}`} />
                        </div>
                        <div className="feature-subtitle">
                          <Text field={name} />
                        </div>
                      </div>
                    </div>
                  );
                } else return null;
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FeatureGenericContentBundaImageCarousel;
