import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import { getBgColor } from "../../../../helper/Color";

const FeatureGenericContentBundaFeaturedArea = (props) => {
  const {
    Title: title = null,
    Subtitle: subtitle = null,
    Area: area = null,
    WhiteBackgroundColor = null,
    // BackgroundColor: backgroundColor = null,
  } = props.fields;

  function renderArea(item, index) {
    const {
      Name: name = null,
      Description: description = null,
      Thumbnail: thumbnail = null,
      ThumbnailAlt: thumbnailAlt = null,
    } = item.fields;
    return (
      <div className="area-container" key={`area-container-${index}`}>
        <img
          src={thumbnail && thumbnail.value ? thumbnail.value : null}
          alt={
            thumbnailAlt && thumbnailAlt.value
              ? thumbnailAlt.value
              : "image_featured_area"
          }
          className="area-image lazyload"
        />
        <div className="feature-subtitle">
          <Text field={name} />
        </div>
        <div className="section-header">
          <Text field={description} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`featuredarea-wrapper 
    ${
      WhiteBackgroundColor && WhiteBackgroundColor.value
        ? "module-bg--white"
        : "module-bg--cream"
    }
    `}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="module-title">
              <Text field={title} />
            </div>
            <div className="module-subheading">
              <Text field={subtitle} />
            </div>
            {area && area.length ? renderArea(area[0], "first") : null}
          </div>
          <div className="col-12 col-md-6">
            {area && area.length
              ? area.slice(1).map((item, index) => renderArea(item, index))
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureGenericContentBundaFeaturedArea;
