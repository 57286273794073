import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/image/gray.png";
import { getBgColor } from "../../../../helper/Color";

const FeatureGenericContentBundaImageTextBlock = (props) => {
  const {
    Image = null,
    ImageAltText = null,
    Title = null,
    Description = null,
    ImagePosition = null,
    BackgroundColor = null,
    Link = null,
  } = props.fields || {};

  let imageTextBlockLayout = "";

  if (
    (Image && Image.value) ||
    (Title && Title.value) ||
    (Description && Description.value)
  ) {
    imageTextBlockLayout = (
      <div className={`bunda-image-text-block ${getBgColor(BackgroundColor)}`}>
        <div
          className={`bunda-image-text-block__wrapper 
          ${
            ImagePosition && ImagePosition.value
              ? ImagePosition.value.toLowerCase()
              : "left"
          }`}
        >
          <div className="row">
            <div className="col col-12 col-md-6">
              <div className="bunda-image-text-block__image">
                {Image && Image.value && (
                  <img
                    alt={
                      ImageAltText && ImageAltText.value
                        ? ImageAltText.value
                        : ""
                    }
                    src={defaultImg}
                    data-src={Image.value}
                    className="lazyload"
                  />
                )}
              </div>
            </div>
            <div className="col col-12 col-md-6">
              <div className="bunda-image-text-block__details">
                <div className="bunda-image-text-block__title">
                  <Text field={Title} />
                </div>
                <div className="bunda-image-text-block__description">
                  <Text field={Description} />
                </div>
                {Link && Link.value && Link.value.href && Link.value.text && (
                  <a
                    href={Link.value.href}
                    target={Link.value.target}
                    className="bunda-image-text-block__button btn btn--small btn--light-primary"
                  >
                    <Text field={{ value: Link.value.text }} />
                  </a>
                )}

                {!(
                  Link &&
                  Link.value &&
                  Link.value.href &&
                  Link.value.text
                ) && (
                  <div className="bunda-image-text-block__icon">
                    <i className="icon icon-artwork-logo" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return imageTextBlockLayout;
};

export default FeatureGenericContentBundaImageTextBlock;
