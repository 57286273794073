import React, { Component } from "react";

class BaseComponent extends Component {
    render() {
        const {
            margin = null
        } = this.props || {};

        return (
            <div className={`base-component ${margin}-margin`} >
                {this.props.children}
            </div>
        );
    }
}

export default BaseComponent;
