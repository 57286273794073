import $ from "jquery";

export const disableScroll = () => {
  if (($(document) && $(document).height()) > $(window).height()) {
    var scrollTop = $("html").scrollTop()
      ? $("html").scrollTop()
      : $("body").scrollTop();
    $("html")
      .addClass("noscroll")
      .css("top", -scrollTop);
  }
};

export const enableScroll = () => {
  var scrollTop = parseInt($("html").css("top"));
  $("html").removeClass("noscroll");
  $("html,body").scrollTop(-scrollTop);
};
