import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/image/gray.png";
import { getBgColor } from "../../../../helper/Color";

const FeatureHeaderBundaPageHeader = (props) => {
  const {
    Logo: logo = null,
    LogoLink: logoLink = null,
    LogoAlt: logoAlt = null,
    HeroImage: heroImage = null,
    HeroImageAlt: heroImageAlt = null,
    HeroVideo: heroVideo = null,
    HeroSource: heroSource = null,
    HeroTitle: heroTitle = null,
    Content: content = null,
    // BackgroundColor = null,
  } = props.fields;

  let isImage = true;

  function renderHeroBanner() {
    let heroBannerLayout = null;
    if (
      heroSource &&
      heroSource.value &&
      heroSource.value.toLowerCase() === "image" &&
      heroImage &&
      heroImage.value
    ) {
      heroBannerLayout = (
        <img
          alt={
            heroImageAlt && heroImageAlt.value
              ? heroImageAlt.value
              : "image_homepage_header"
          }
          src={defaultImg}
          data-src={heroImage.value}
          className="main-hero-image lazyload"
        />
      );
    } else if (
      heroSource &&
      heroSource.value &&
      heroSource.value.toLowerCase() === "video" &&
      heroVideo &&
      heroVideo.value
    ) {
      isImage = false;
      heroBannerLayout = (
        <video
          className="main-hero-image"
          controls={false}
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
        >
          <source src={heroVideo.value} type="video/mp4" />
          <source src={heroVideo.value} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      );
    } else if (heroImage && heroImage.value) {
      heroBannerLayout = (
        <img
          alt={
            heroImageAlt && heroImageAlt.value
              ? heroImageAlt.value
              : "image_homepage_header"
          }
          src={defaultImg}
          data-src={heroImage.value}
          className="main-hero-image lazyload"
        />
      );
    } else {
      heroBannerLayout = (
        <img
          alt={"default-image"}
          src={defaultImg}
          className="main-hero-image"
        />
      );
    }

    return heroBannerLayout;
  }

  return (
    <div className="homepage-header-wrapper">
      <div className="header-artwork-container">
        <div className="header-artwork">
          <div className="inner-circle" />
          <div className="outer-circle" />
        </div>
      </div>
      <div className="logo-wrapper">
        <div className="container">
          <div className="logo-container">
            {logo && logo.value ? (
              <a href={logoLink && logoLink.value ? logoLink.value : "/"}>
                <img
                  alt={
                    logoAlt && logoAlt.value ? logoAlt.value : "224-bunda-logo"
                  }
                  src={defaultImg}
                  data-src={logo.value}
                  className="lazyload"
                />
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* <div
        className="main-header"
        style={{
          backgroundImage: `url(${logo && logo.value ? logo.value : null})`,
        }}
      ></div> */}
      <div
        className={`main-hero-container ${
          isImage ? "main-hero--is-image" : ""
        }`}
      >
        {renderHeroBanner()}
        {isImage && (
          <div className="main-hero-title">
            <div className="container">
              <RichText field={heroTitle} />
            </div>
          </div>
        )}
      </div>
      {/* <div className={`main-content__wrapper ${getBgColor(BackgroundColor)}`}> */}
      <div className="container">
        <div className="main-content">
          <Text field={content} />
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default FeatureHeaderBundaPageHeader;
