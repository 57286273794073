import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

const FeatureFooterBundaFooter = (props) => {
  const {
    FooterLinksDescription = null,
    FooterLinks = null,
  } = props.fields || {};

  const lineLayout = (moreLines) => {
    let lineLength = 14;

    if (moreLines) {
      lineLength = 15;
    }
    let horizontalList = [];
    for (var counter = 0; counter < lineLength; counter++) {
      horizontalList.push(<span key={"lines-" + counter}></span>);
    }
    return (
      <div className="bunda-footer__lines--horizontal">{horizontalList}</div>
    );
  };

  return (
    <div className={`bunda-footer module-bg--darkbrown`}>
      <div className="container">
        {FooterLinksDescription &&
          FooterLinksDescription.value &&
          FooterLinks &&
          FooterLinks.length > 0 && (
            <div className="bunda-footer__links">
              <div className="bunda-footer__links--item">
                <Text field={FooterLinksDescription} />
              </div>
              {FooterLinks.map((item, index) => {
                const { LinkTitle = null, LinkUrl = null } = item.fields || {};
                let linkLayout;

                if (LinkTitle && LinkTitle.value && LinkUrl && LinkUrl.value) {
                  linkLayout = (
                    <div
                      key={"footer-links" + index}
                      className="bunda-footer__links--item"
                    >
                      <a href={LinkUrl.value} target="_blank">
                        <Text field={LinkTitle} />
                      </a>
                    </div>
                  );
                }
                return linkLayout;
              })}
            </div>
          )}
      </div>
      <div className="bunda-footer__lines">
        {lineLayout(true)}
        {lineLayout()}
        {lineLayout(true)}
        {lineLayout()}
      </div>
    </div>
  );
};

export default FeatureFooterBundaFooter;
