import React, { Component } from "react";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import Flickity from "react-flickity-component";
import { disableScroll, enableScroll } from "../../../../helper/ScrollHelper";
import defaultImg from "../../../../assets/image/gray.png";
import { addClassLazyload } from "../../../../helper/AddClassLazyloadHelper";

class FeatureGenericContentBundaRoomListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilter: "",
      activeValue: "",
      show: false,
      isDesktop: false,
      openGallery: false,
      photos: [],
      filters: [],
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.handleCategories();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  getUrlParameter(querystringParam) {
    querystringParam = querystringParam
      .replace(/[\[]/, "\\[")
      .replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + querystringParam + "=([^&#]*)");
    var results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  handleCategories = () => {
    const { RoomCategories: categories = null } = this.props.fields;
    const { filters } = this.state;
    if (categories && categories.length) {
      categories.map((item, index) => {
        const {
          CategoryFilterValue: filter = null,
          CategoryName: name = null,
          RoomCount: count = null,
          CategoryDescription: description = null,
        } = item.fields;
        if (
          name &&
          name.value &&
          filter &&
          filter.value &&
          count &&
          count.value
        ) {
          filters.push({
            name: name.value + " (" + count.value + ")",
            value: filter.value,
            headerName: name.value,
            description: (description && description.value) || "",
          });
        }
      });
    }

    if (filters && filters.length && filters[0]) {
      let index = 0;

      for (const filter in filters) {
        if (
          filters[filter].value
            .toLowerCase()
            .indexOf(this.getUrlParameter("filter_cat").toLowerCase()) > -1
        ) {
          index = filter;
          break;
        }
      }

      const { name = null, value = null } = filters[index];

      this.setState({
        activeFilter: name,
        activeValue: value,
      });
    }
  };

  handleResize = () => {
    if (window && window.innerWidth < 992) {
      this.setState({ isDesktop: false });
      document.addEventListener("mousedown", this.handleClick, false);
    } else {
      this.setState({ isDesktop: true });
      document.removeEventListener("mousedown", this.handleClick, false);
    }
  };

  handleClick = (e) => {
    if (this.state.show) {
      if (this.node.contains(e.target)) {
        return;
      } else {
        this.handleBlur();
      }
    }
  };

  handleBlur = () => {
    setTimeout(() => {
      this.setState({ show: false });
    }, 120);
  };

  renderFilters = () => {
    const { activeFilter, activeValue, show, filters, isDesktop } = this.state;
    if (filters && filters.length) {
      return (
        <div className="room-categories" ref={(node) => (this.node = node)}>
          <div
            className="dropdown-wrapper dropdown-font"
            onClick={() => this.setState({ show: !show })}
          >
            <div className="selected-category">{activeFilter}</div>
            <i className={`icon icon-chevron-${show ? "up" : "down"}`} />
          </div>
          {(show || isDesktop) && (
            <ul className="dropdown-list">
              {filters.map((item, index) => {
                const { name = null, value = null } = item;
                return (
                  <li
                    className={`dropdown-font ${
                      activeValue === value ? "selected" : ""
                    }`}
                    key={`RoomCategoryItem${index}`}
                    onClick={() => {
                      this.setState({
                        show: false,
                        activeFilter: name,
                        activeValue: value,
                      });
                      addClassLazyload("bunda-room-listing-wrapper");
                    }}
                  >
                    <div>{name}</div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      );
    } else return null;
  };
  renderHeader = () => {
    const { filters, activeValue } = this.state;
    let header =
      filters &&
      filters.length &&
      filters.filter(
        (el) => el.value.toLowerCase() == activeValue.toLowerCase()
      )[0];

    if (header) {
      const { headerName = null, description = null } = header;
      return (
        <div className="room-header">
          <div className="module-title">
            <Text field={{ value: headerName }} />
          </div>
          <div className="module-subtitle offset-lg-1 col-lg-10">
            <RichText field={{ value: description }} />
          </div>
        </div>
      );
    } else return null;
  };
  renderRooms = () => {
    const {
      RoomBookingHeader: headerText = null,
      Rooms: rooms = null,
    } = this.props.fields;
    const { activeValue } = this.state;
    let list = rooms;
    if (activeValue) {
      list = rooms.filter(
        (el) =>
          el.fields.RoomType.value.toLowerCase() === activeValue.toLowerCase()
      );
    }
    if (list && list.length) {
      return list.map((item, index) => {
        const {
          RoomImage: image = null,
          RoomImageAltText: alt = null,
          RoomName: name = null,
          RoomType: type = null,
          RoomDescription: description = null,
          RoomCapacity: capacity = null,
          RoomCostPerHour: cost = null,
          RoomBookingText: buttonText = null,
          RoomBookingUrl: url = null,
          RoomAmenities: amenities = null,
          RoomImageGallery: gallery = null,
        } = item.fields;

        let images = [];

        if (gallery && gallery.length) {
          gallery.map((item) => {
            const { Image: imageGallery } = item.fields;
            if (imageGallery && imageGallery.value) {
              images.push(item);
            }
          });
        }

        let hasImageGallery = images && images.length;

        if (image && image.value) {
          images.unshift({
            fields: {
              Image: image,
              ImageAltText: alt,
            },
          });
        }

        return (
          <div
            className={`room-wrapper ${activeValue ? "" : "no-filters"}`}
            key={`RoomListingItem${index}`}
          >
            <div className="row">
              <div className="room-col col-12 col-lg-8">
                <div className="room-info">
                  <div
                    className="room-image"
                    onClick={() =>
                      images &&
                      images.length &&
                      this.setState({ photos: images, openGallery: true }, () =>
                        disableScroll()
                      )
                    }
                    style={images && images.length ? { cursor: "pointer" } : {}}
                  >
                    {hasImageGallery ? (
                      <div className="room-gallery-btn">
                        <div className="room-gallery-text__desktop">
                          <i className="icon icon-show-images" />
                          Show all photos
                        </div>
                        <div className="room-gallery-text__mobile">
                          <i className="icon icon-show-images" />
                          1/
                          {images.length}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <img
                      alt={alt && alt.value ? alt.value : ""}
                      src={defaultImg}
                      data-src={image && image.value ? image.value : defaultImg}
                      className="lazyload"
                    />
                  </div>
                  <div className="room-details">
                    {name && name.value && (
                      <div className="module-heading">
                        <Text field={name} />
                      </div>
                    )}
                    {type && type.value && (
                      <div className="section-header">
                        <Text field={type} />
                      </div>
                    )}
                    {description && description.value && (
                      <div className="module-subheading">
                        <RichText field={description} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="booking-col col-12 col-lg-4">
                <div className="order-3">
                  <div className="module-subtitle">
                    <Text field={headerText} />
                  </div>
                  <div className="row no-gutters">
                    {capacity && capacity.value && (
                      <div className="col-auto">
                        <div className="section-header">Capacity</div>
                        <div className="space-info">
                          <div className="module-heading">
                            <Text field={capacity} />
                          </div>
                          <i className="icon icon-person" />
                        </div>
                      </div>
                    )}
                    {cost && cost.value && (
                      <div className="col">
                        <div className="section-header">From</div>

                        <div className="space-info">
                          <div className="module-heading">
                            $<Text field={cost} />
                          </div>
                          <div className="section-header">/hour</div>
                        </div>
                      </div>
                    )}
                  </div>
                  {buttonText && buttonText.value && url && url.value && (
                    <div className="">
                      <a
                        href={url.value}
                        target="_blank"
                        className="btn btn-subscribe btn--small btn--light-primary"
                      >
                        {buttonText.value}
                        <i className="icon icon-arrow-right" />
                      </a>
                    </div>
                  )}
                </div>
                <div className="room-divider order-2" />
                {amenities && amenities.length ? (
                  <div className="amenities order-1">
                    <div className="module-subheading--large">Amenities</div>
                    {amenities.map((item, index) => {
                      const {
                        IconClass: icon = null,
                        Name: name = null,
                      } = item.fields;
                      if (icon && icon.value && name && name.value) {
                        return (
                          <div className="feature" key={`feature${index}`}>
                            <div className="icon-container">
                              <i className={`icon icon-${icon.value}`} />
                            </div>
                            <div className="feature-subtitle">
                              <Text field={name} />
                            </div>
                          </div>
                        );
                      } else return null;
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        );
      });
    } else return null;
  };
  renderGallery = () => {
    const { photos } = this.state;
    return (
      <div className="room-gallery-wrapper">
        <i
          className="icon icon-close"
          onClick={() =>
            this.setState({ openGallery: false }, () => enableScroll())
          }
        />
        <Flickity className={"carousel"} reloadOnUpdate static>
          {photos.map((item, index) => {
            const { Image: url, ImageAltText: alt } = item.fields;
            if (url && url.value) {
              return (
                <img
                  src={url.value}
                  className="carousel-cell"
                  key={`carousel${index}`}
                  alt={alt && alt.value ? alt.value : "carousel_image"}
                />
              );
            } else return null;
          })}
        </Flickity>
      </div>
    );
  };

  render() {
    const { Rooms: rooms = null } = this.props.fields;
    const { openGallery } = this.state;
    if (rooms && rooms.length) {
      return (
        <div className="bunda-room-listing-wrapper module-bg--white">
          {openGallery && this.renderGallery()}
          {this.renderFilters()}
          <div className="container">
            {this.renderHeader()}
            {this.renderRooms()}
          </div>
        </div>
      );
    } else return null;
  }
}

export default FeatureGenericContentBundaRoomListing;
