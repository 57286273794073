import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import BaseComponent from "../../../Common/BaseComponent";
import { getBgColor } from "../../../../helper/Color";

const FeatureGenericContentBundaSummary = (props) => {
  const {
    ComponentTitle = null,
    SummaryItems = null,
    // BackgroundColor = null
  } = props.fields || {};

  let summaryLayout = "";

  // TODO: Remove if the BE implemented the "BackgroundColor" field
  let BackgroundColor = {
    "fields": {
      "Colour": {
        "value": "White"
      },
      "Hex": {
        "value": "#ffffff"
      }
    }
  }

  if (SummaryItems && SummaryItems.length > 0) {
    summaryLayout = (
      <BaseComponent margin="custom">
        <div className={`bunda-summary ${getBgColor(BackgroundColor)}`}>
          <div className="container">
            {ComponentTitle && ComponentTitle.value && (
              <div className="bunda-summary__header">
                <Text field={ComponentTitle} />
              </div>
            )}
            <div className="bunda-summary__content row">
              {SummaryItems.map((item, index) => {
                const { Title = null, Subtext = null, Icon = null } =
                  item.fields || {};

                let summaryItemLayout;

                if (
                  (Title && Title.value) ||
                  (Subtext && Subtext.value) ||
                  (Icon && Icon.value)
                ) {
                  summaryItemLayout = (
                    <div
                      key={"bunda-summary-list" + index}
                      className="col col-12 col-md-6 col-xl-4"
                    >
                      <div className="bunda-summary__item">
                        <div className="bunda-summary__details">
                          {Icon && Icon.value && (
                            <div className="bunda-summary__icon">
                              <i className={`icon icon-${Icon.value}`} />
                            </div>
                          )}
                          <div
                            className={`bunda-summary__text ${Icon && Icon.value ? "" : "full-width"
                              }`}
                          >
                            {Title && Title.value && (
                              <div className="bunda-summary__title">
                                <RichText field={Title} />
                              </div>
                            )}
                            {Subtext && Subtext.value && (
                              <div className="bunda-summary__subtext">
                                <Text field={Subtext} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                return summaryItemLayout;
              })}
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }
  return summaryLayout;
};

export default FeatureGenericContentBundaSummary;
