import React from "react";
import {
  Placeholder,
  VisitorIdentification
} from "@sitecore-jss/sitecore-jss-react";
import Helmet from "react-helmet";
import TagManager from "react-gtm-module";
import "./assets/app.css";
import objectFitImages from "object-fit-images";
import "lazysizes";
import { get } from "lodash";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { url: "", domain: "" };
  }

  componentDidMount() {
    const { route, context } = this.props;
    TagManager.initialize({ gtmId: "GTM-KNLL2BD7" });

    if (document) {
      objectFitImages();

      // Canonical URL
      let canonicalSrc = get(route, "fields.CanonicalUrl.value", "");
      if (!canonicalSrc) {
        canonicalSrc = document.URL;
      }
      let canonicalWrapper = document.getElementById("canonical");
      if (canonicalWrapper) {
        canonicalWrapper.href = canonicalSrc;
      }
    }

    if (window) {
      this.setState({
        url: window.location.href,
        domain: window.location.origin
      });
    }
  }

  render() {
    const { route, context } = this.props;
    const { url, domain } = this.state;
    let metaImage = "";
    let metaImageAlt = "";

    return (
      <React.Fragment>
        <Helmet>
          <title>
            {route.fields &&
              route.fields.PageTitle &&
              route.fields.PageTitle.value
              ? route.fields.PageTitle.value
              : ""}
          </title>

          {route.fields &&
            route.fields.MetaDescription &&
            route.fields.MetaDescription.value && (
              <meta
                name="description"
                content={route.fields.MetaDescription.value}
              />
            )}

          {route.fields &&
            route.fields.RobotsTag &&
            route.fields.RobotsTag.value && (
              <meta name="robots" content={route.fields.RobotsTag.value} />
            )}

          {route.fields
            ? route.fields.MetaImageUrl && route.fields.MetaImageUrl.value
              ? (metaImage = route.fields.MetaImageUrl.value) &&
              (metaImageAlt = route.fields.MetaImageAltText.value)
              : route.fields.MetaImage &&
                route.fields.MetaImage.value &&
                route.fields.MetaImage.value.src
                ? (metaImage = domain + route.fields.MetaImage.value.src) &&
                (metaImageAlt = route.fields.MetaImage.value.alt)
                : ""
            : ""}

          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={get(route, "fields.PageTitle.value", "")}
          />
          <meta
            property="og:description"
            content={get(route, "fields.MetaDescription.value", "")}
          />
          <meta property="og:url" content={url} />
          <meta property="og:image" content={metaImage} />
          <meta property="og:image:alt" content={metaImageAlt} />
          {context.bundaPageInfo && context.bundaPageInfo.siteName && (
            <meta property="og:site_name" itemProp="name" content={context.bundaPageInfo.siteName}/>
          )}

          <meta
            name="twitter:title"
            content={get(route, "fields.PageTitle.value", "")}
          />
          <meta
            name="twitter:description"
            content={get(route, "fields.MetaDescription.value", "")}
          />
          <meta name="twitter:card" content="website" />
          <meta name="twitter:url" content={url} />
          <meta name="twitter:image" content={metaImage} />
          <meta name="twitter:image:alt" content={metaImageAlt} />
        </Helmet>
        <VisitorIdentification />
        <input
          type="hidden"
          value={route.itemId}
          data-target-db={route.databaseName}
          name="ContextItem"
        />
        <Placeholder name="224-header" rendering={route} />
        <Placeholder name="224-body" rendering={route} />
        <Placeholder name="224-footer" rendering={route} />
      </React.Fragment>
    );
  }
}

export default Layout;
