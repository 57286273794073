import React from 'react';
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import { getBgColor } from "../../../../helper/Color";
import defaultImg from "../../../../assets/image/gray.png";

const FeatureGenericContentBundaContactList = (props) => {
  const {
    LogoImage = null,
    LogoImageAlt = null,
    Address = null,
    PhoneNumber = null,
    EmailAddress = null,
    Contacts = null,
    BackgroundColor = null
  } = props.fields;

  if ((Address && Address.value) ||
    (PhoneNumber && PhoneNumber.value) ||
    (EmailAddress && EmailAddress.value) ||
    (Contacts && Contacts.length > 0)) {
    return (
      <div className={`bunda-contact-list ${getBgColor(BackgroundColor)}`}>
        <div className="container">
          {LogoImage &&
            LogoImage.value &&
            <div className="bunda-contact-list__logo">
              <img
                alt={
                  LogoImageAlt && LogoImageAlt.value
                    ? LogoImageAlt.value
                    : "bunda-contact-list-logo"
                }
                src={defaultImg}
                data-src={LogoImage.value}
                className="lazyload"
              />
            </div>
          }

          {((Address && Address.value) ||
            (PhoneNumber && PhoneNumber.value) ||
            (EmailAddress && EmailAddress.value)) &&
            <div className="bunda-contact-list__company module-subheading--large">
              {Address && Address.value &&
                <div className="bunda-contact-list__company--address">
                  <Text field={Address} />
                </div>
              }
              {PhoneNumber && PhoneNumber.value &&
                <div className="bunda-contact-list__company--number">
                  <Text field={PhoneNumber} />
                </div>
              }
              {EmailAddress && EmailAddress.value &&
                <a href={`mailto:${EmailAddress.value}`} className="bunda-contact-list__company--email">
                  <Text field={{ value: "Email" }} />
                </a>
              }

            </div>
          }

          {Contacts &&
            Contacts.length > 0 &&
            <div className="bunda-contact-list__person">
              {Contacts.map((item, index) => {
                const {
                  ContactName = null,
                  ContactTitle = null,
                  ContactNumber = null,
                  ContactEmail = null
                } = item.fields;

                let phoneNumberLayout = "";

                if (ContactNumber && ContactNumber.value) {
                  let numberVal;
                  if (/;/g.test(ContactNumber.value)) {
                    numberVal = ContactNumber.value.split(";");
                    phoneNumberLayout =
                      numberVal.map((number, index) => {
                        return (
                          <div className="bunda-contact-list__person--number"
                            key={`person-number-${index}`}>
                            <Text field={{ value: number }} />
                          </div>
                        )
                      })
                  } else {
                    phoneNumberLayout =
                      <div className="bunda-contact-list__person--number">
                        <Text field={ContactNumber} />
                      </div>
                  }
                }
                if ((ContactName && ContactName.value) ||
                  (ContactTitle && ContactTitle.value) ||
                  (ContactNumber && ContactNumber.value) ||
                  (ContactEmail && ContactEmail.value)) {
                  return (
                    <div className="bunda-contact-list__person--wrapper"
                      key={`person-details-${index}`}>
                      {((ContactName && ContactName.value) ||
                        (ContactTitle && ContactTitle.value)) &&
                        <div className="bunda-contact-list__person--details">
                          {ContactName && ContactName.value &&
                            <div className="bunda-contact-list__person--name module-subheading--large">
                              <Text field={ContactName} />
                            </div>
                          }
                          {ContactTitle && ContactTitle.value &&
                            <div className="bunda-contact-list__person--role contact-roles">
                              <Text field={ContactTitle} />
                            </div>
                          }
                        </div>
                      }
                      <div className="bunda-contact-list__person--contacts module-subheading--large">
                        {phoneNumberLayout}
                        {ContactEmail &&
                          ContactEmail.value &&
                          <div className="bunda-contact-list__person--email">
                            <a href={`mailto:${ContactEmail.value}`}>
                              <Text field={{ value: "Email" }} />
                            </a>
                          </div>
                        }
                      </div>
                    </div>
                  )
                }
                else {
                  return null
                }
              })
              }
            </div>
          }

        </div>
      </div>
    )
  }
  else {
    return null;
  }
};

export default FeatureGenericContentBundaContactList;
