import React from "react";
import defaultImg from "../../../../assets/image/gray.png";

class FeatureHeaderBundaGenericPageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showNav: false };
  }

  componentDidMount() {}

  renderLogo = () => {
    const { Logo = null, LogoAltText = null, LogoLink = null } =
      this.props.fields || {};

    let imageLayout = "",
      logoLayout = "";

    if (Logo && Logo.value) {
      logoLayout = imageLayout = (
        <img
          alt={LogoAltText && LogoAltText.value ? LogoAltText.value : ""}
          src={defaultImg}
          data-src={Logo.value}
          className="lazyload"
        />
      );
    }

    if (LogoLink && LogoLink.value) {
      logoLayout = (
        <a
          href={LogoLink.value}
          className="bunda-generic-pageheader__logo--link"
        >
          {imageLayout}
        </a>
      );
    }

    return logoLayout;
  };

  render() {
    const { showNav } = this.state;
    return (
      <div className="bunda-generic-pageheader">
        <div className="container">
          <div className="bunda-generic-pageheader__logo">
            {this.renderLogo()}
          </div>
          {/* <div className="bunda-generic-pageheader__nav-wrapper">
            <i
              className={`icon icon-${showNav ? "close" : "menu"}`}
              onClick={() => this.setState({ showNav: !showNav })}
            />
            <div
              className={`bunda-generic-pageheader__nav-links ${
                showNav ? "show-nav" : "hide-nav"
              }`}
            >
              <div>About</div>
              <div>Conference Centre</div>
              <div>Location</div>
              <div>Building services</div>
              <div>Leasing</div>
              <div>Contact</div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default FeatureHeaderBundaGenericPageHeader;
