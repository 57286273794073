export const getBgColor = (BackgroundColor) => {
  let color = "";
  let bgColor = "";
  let hex = "";
  if (BackgroundColor && BackgroundColor.fields && BackgroundColor.fields.Hex && BackgroundColor.fields.Hex.value) {
    hex = BackgroundColor.fields.Hex.value
  }
  if (hex) {
    color = hex.toLowerCase();
    switch (color) {
      case "#574e50":
        bgColor = "module-bg--darkbrown";
        break;
      case "#ffffff":
        bgColor = "module-bg--white";
        break;
      case "#f7f1ed":
        bgColor = "module-bg--cream";
        break;
    }
  } else {
    bgColor = "module-bg--white";
  }
  return bgColor;
};
