import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { getBgColor } from "../../../../helper/Color";

const FeatureGenericContentBundaRichText = (props) => {
  const {
    BackgroundColor = null,
    Details = null
  } = props.fields;

  return (
    <div className={`bunda-richtext ${getBgColor(BackgroundColor)}`}>
      <div className="container">
        <div className="bunda-richtext__wrapper">
          <RichText field={Details} />
        </div>
      </div>
    </div>
  );

};

export default FeatureGenericContentBundaRichText;
