import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { getBgColor } from "../../../../helper/Color";
import defaultImg from "../../../../assets/image/gray.png";

const FeatureGenericContentBundaLeasingContacts = (props) => {
  const {
    LogoImage = null,
    LogoImageAlt = null,
    Address = null,
    Contacts = null,
    Button = null,
    BackgroundColor = null,
  } = props.fields || {};

  if ((LogoImage && LogoImage.value) ||
    (Address && Address.value) ||
    (Contacts && Contacts.length > 0)) {
    return (
      <div className={`bunda-leasing-contacts ${getBgColor(BackgroundColor)}`}>
        <div className="container">
          {LogoImage &&
            LogoImage.value &&
            <div className="bunda-leasing-contacts__logo">
              <img
                alt={
                  LogoImageAlt && LogoImageAlt.value
                    ? LogoImageAlt.value
                    : "bunda-leasing-contacts-logo"
                }
                src={defaultImg}
                data-src={LogoImage.value}
                className="lazyload"
              />
            </div>
          }
          {Address && Address.value &&
            <div className="bunda-leasing-contacts__address module-subtitle">
              <Text field={Address} />
            </div>
          }
          {Contacts &&
            Contacts.length > 0 &&
            <div className="bunda-leasing-contacts__details">
              {Contacts.map((item, index) => {
                const {
                  ContactName = null,
                  ContactImageUrl = null,
                  ContactImageAltText = null,
                  ContactNumber = null,
                  ContactEmail = null
                } = item.fields;

                let phoneNumberLayout = "";

                if (ContactNumber && ContactNumber.value) {
                  let numberVal;
                  if (/;/g.test(ContactNumber.value)) {
                    numberVal = ContactNumber.value.split(";");
                    phoneNumberLayout =
                      numberVal.map((number, index) => {
                        return (
                          <div className="bunda-leasing-contacts__number"
                            key={`leasing-contact-number-${index}`}>
                            <Text field={{ value: number }} />
                          </div>
                        )
                      })
                  } else {
                    phoneNumberLayout =
                      <div className="bunda-leasing-contacts__number">
                        <Text field={ContactNumber} />
                      </div>
                  }
                }
                if ((ContactName && ContactName.value) ||
                  (ContactImageUrl && ContactImageUrl.value) ||
                  (ContactNumber && ContactNumber.value) ||
                  (ContactEmail && ContactEmail.value)) {
                  return (
                    <div className="bunda-leasing-contacts__details--wrapper module-subheading--large"
                      key={`leasing-contact-details-${index}`}>

                      {ContactImageUrl && ContactImageUrl.value &&
                        <div className="bunda-leasing-contacts__image">
                          <img
                            alt={
                              ContactImageAltText && ContactImageAltText.value
                                ? ContactImageAltText.value
                                : "leasing-contacts-logo"
                            }
                            src={defaultImg}
                            data-src={ContactImageUrl.value}
                            className="lazyload"
                          />
                        </div>
                      }
                      {ContactName && ContactName.value &&
                        <div className="bunda-leasing-contacts__name">
                          <Text field={ContactName} />
                        </div>
                      }
                      <div className="bunda-leasing-contacts__contacts">
                        {phoneNumberLayout}
                        {ContactEmail &&
                          ContactEmail.value &&
                          <div className="bunda-leasing-contacts__email">
                            <a href={`mailto:${ContactEmail.value}`}>
                              <Text field={{ value: "Email" }} />
                            </a>
                          </div>
                        }
                      </div>
                    </div>
                  )
                }
                else {
                  return null
                }
              })
              }
            </div>
          }

          {Button && Button.value && Button.value.href && Button.value.text && (
            <a
              href={Button.value.href}
              target={Button.value.target}
              className="bunda-leasing-contacts__button btn btn--medium btn--light-primary"
            >
              <Text field={{ value: Button.value.text }} />
            </a>
          )}
        </div>
      </div >
    )
  }
  else {
    return null
  }
};

export default FeatureGenericContentBundaLeasingContacts;