import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { getBgColor } from "../../../../helper/Color";

const FeatureGenericContentBundaSummaryList = (props) => {
  const {
    Header = null,
    Description = null,
    ListItems = null,
    BackgroundColor = null
  } = props.fields;

  if (ListItems && ListItems.length > 0) {
    return (
      <div className={`bunda-summary-list ${getBgColor(BackgroundColor)}`}>
        <div className="container">
          {Header &&
            Header.value &&
            <div className="bunda-summary-list__header module-title">
              <Text field={Header} />
            </div>
          }
          {Description &&
            Description.value &&
            <div className="bunda-summary-list__description module-subtitle--medium">
              <Text field={Description} />
            </div>
          }
          <div className="bunda-summary-list__content">
            {ListItems.map((item, index) => {
              const {
                Title = null,
                Description = null,
                Link = null,
                Icon = null,
                ReverseTitleDescription = null
              } = item.fields

              let summaryDetailsLayout = "";
              if (!(ReverseTitleDescription && ReverseTitleDescription.value)) {
                summaryDetailsLayout =
                  <div className="bunda-summary-list__details"
                    key={`summary-list-${index}`}>
                    <div className="bunda-summary-list__icon">
                      {Icon &&
                        Icon.value &&
                        <i className={`icon icon-${Icon.value.toLowerCase()}`} />
                      }
                    </div>
                    <div className="bunda-summary-list__text">
                      {Title &&
                        Title.value &&
                        <div className="bunda-summary-list__title feature-title--medium">
                          <Text field={Title} />
                        </div>

                      }
                      {Description &&
                        Description.value &&
                        <div className="bunda-summary-list__subtitle feature-subtitle">
                          <Text field={Description} />
                        </div>

                      }
                      {Link &&
                        Link.value &&
                        Link.value.href &&
                        Link.value.text &&
                        <a
                          href={Link.value.href}
                          target={Link.value.target}
                          className="bunda-summary-list__link module-link"
                        >
                          <Text field={{ value: Link.value.text }} />
                        </a>
                      }
                    </div>
                  </div>
              } else {
                summaryDetailsLayout =
                  <div className="bunda-summary-list__details is-reverse"
                    key={`summary-list-${index}`}>
                    <div className="bunda-summary-list__icon">
                      {Icon &&
                        Icon.value &&
                        <i className={`icon icon-${Icon.value.toLowerCase()}`} />
                      }
                    </div>
                    <div className="bunda-summary-list__text">
                      {Description &&
                        Description.value &&
                        <div className="bunda-summary-list__subtitle feature-subtitle">
                          <Text field={Description} />
                        </div>

                      }
                      {Title &&
                        Title.value &&
                        <div className="bunda-summary-list__title feature-title--medium">
                          <Text field={Title} />
                        </div>

                      }
                      {Link &&
                        Link.value &&
                        Link.value.href &&
                        Link.value.text &&
                        <a
                          href={Link.value.href}
                          target={Link.value.target}
                          className="bunda-summary-list__link module-link"
                        >
                          <Text field={{ value: Link.value.text }} />
                        </a>
                      }
                    </div>
                  </div>
              }

              return summaryDetailsLayout;
            })}
          </div>

        </div>
      </div>
    )
  }
  else {
    return null
  }
};

export default FeatureGenericContentBundaSummaryList;
