import React, { Component } from "react";
import { Link, Text } from "@sitecore-jss/sitecore-jss-react";
import { disableScroll, enableScroll } from "../../../../helper/ScrollHelper";

class FeatureGenericContentBundaNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = { showNav: false };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (window && window.innerWidth > 991 && this.state.showNav) {
      this.setState({ showNav: false });
    }
  };

  render() {
    const { showNav } = this.state;
    const { NavigationFields: nav } = this.props.fields;
    if (nav && nav.length) {
      return (
        <div
          className={`bunda-navigation-wrapper ${showNav ? "nav__shown" : ""}`}
        >
          <div className="container">
            <i
              className={`icon icon-${showNav ? "close" : "menu"}`}
              onClick={() =>
                this.setState({ showNav: !showNav }, () =>
                  showNav ? enableScroll() : disableScroll()
                )
              }
            />
            <div className={`nav-links ${showNav ? "show-nav" : "hide-nav"}`}>
              <div className="container">
                {nav.map((item, index) => {
                  const {
                    Link: link,
                    LinkName: name,
                    IsActive: isActive,
                  } = item.fields;
                  if (
                    name &&
                    name.value &&
                    link &&
                    link.value &&
                    link.value.href
                  ) {
                    return (
                      <div
                        className={isActive && isActive.value ? "active" : ""}
                        key={`NavItem${index}`}
                      >
                        <Link field={link}>
                          <Text field={name} />
                        </Link>
                      </div>
                    );
                  } else return null;
                })}
              </div>
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

export default FeatureGenericContentBundaNavigation;
