import React, { Component } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/image/gray.png";

class FeatureGenericContentBundaHero extends Component {
  constructor(props) {
    super(props);
    this.state = { isPaused: true };
  }

  componentDidMount() {
    if (document) {
      const vid = document.querySelector("video.bunda-hero__image");
      if (vid) {
        vid.onplaying = () => {
          this.setState({ isPaused: false });
        };
        vid.onpause = () => {
          this.setState({ isPaused: true });
        };
      }
    }
  }

  renderHeroBanner = (props) => {
    const {
      HeroImage: heroImage = null,
      HeroImageAlt: heroImageAlt = null,
      HeroVideo: heroVideo = null,
      HeroSource: heroSource = null,
    } = props.fields || {};

    let heroBannerLayout = null;
    if (
      heroSource &&
      heroSource.value &&
      heroSource.value.toLowerCase() === "image" &&
      heroImage &&
      heroImage.value
    ) {
      heroBannerLayout = (
        <img
          alt={
            heroImageAlt && heroImageAlt.value
              ? heroImageAlt.value
              : "image-hero-banner"
          }
          src={defaultImg}
          data-src={heroImage.value}
          className="bunda-hero__image lazyload"
        />
      );
    } else if (
      heroSource &&
      heroSource.value &&
      heroSource.value.toLowerCase() === "video" &&
      heroVideo &&
      heroVideo.value
    ) {
      heroBannerLayout = (
        <video
          className="bunda-hero__image"
          controls={true}
          autoPlay={false}
          loop={false}
          muted={false}
        >
          <source src={heroVideo.value} type="video/mp4" />
          <source src={heroVideo.value} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      );
    } else if (heroImage && heroImage.value) {
      heroBannerLayout = (
        <img
          alt={
            heroImageAlt && heroImageAlt.value
              ? heroImageAlt.value
              : "image-hero-banner"
          }
          src={defaultImg}
          data-src={heroImage.value}
          className="bunda-hero__image lazyload"
        />
      );
    } else {
      heroBannerLayout = (
        <img
          alt={"default-image"}
          src={defaultImg}
          className="bunda-hero__image"
        />
      );
    }

    return heroBannerLayout;
  };

  render() {
    const { HeroTitle: heroTitle = null } = this.props.fields;
    const { isPaused } = this.state;
    return (
      <div className="bunda-hero">
        <div
          className={`bunda-hero__container ${
            isPaused ? "main-hero--is-image" : ""
          }`}
        >
          {this.renderHeroBanner(this.props)}
          {isPaused && (
            <div className="bunda-hero__title">
              <div className="container">
                <RichText field={heroTitle} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FeatureGenericContentBundaHero;
