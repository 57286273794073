import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/image/gray.png";
import BaseComponent from "../../../Common/BaseComponent";
import { getBgColor } from "../../../../helper/Color";

const FeatureGenericContentBundaThirdPartyList = (props) => {
  const {
    Title = null,
    ThirdParties = null,
    // BackgroundColor = null
  } = props.fields || {};

  let bundaThirdPartyLayout = "";

  // TODO: Remove if the BE implemented the "BackgroundColor" field
  let BackgroundColor = {
    "fields": {
      "Colour": {
        "value": "White"
      },
      "Hex": {
        "value": "#ffffff"
      }
    }
  }

  if (ThirdParties && ThirdParties.length > 0) {
    bundaThirdPartyLayout = (
      <BaseComponent margin="custom">
        <div
          className={`bunda-third-party ${ThirdParties.length > 1 ? "has-more-list" : ""} 
          ${getBgColor(BackgroundColor)}`}
        >
          <div className="container">
            <div className="bunda-third-party__title">
              <Text field={Title} />
            </div>

            <div className="bunda-third-party__list">
              {ThirdParties.map((item, index) => {
                const {
                  ThirdPartyLogo = null,
                  ThirdPartyLogoAltText = null,
                  ThirdPartyLogoLink = null,
                } = item.fields || {};

                let logoLayout = "";

                if (ThirdPartyLogo && ThirdPartyLogo.value) {
                  if (ThirdPartyLogoLink && ThirdPartyLogoLink.value && ThirdPartyLogoLink.value.href) {
                    logoLayout = (
                      <a href={ThirdPartyLogoLink.value.href}
                        target={ThirdPartyLogoLink.value.target}
                        key={"third-party-link" + index}
                      >
                        <img 
                          alt={ThirdPartyLogoAltText && ThirdPartyLogoAltText.value ?
                            ThirdPartyLogoAltText.value :
                            "third-party-logo" + index}
                          src={defaultImg}
                          data-src={ThirdPartyLogo.value}
                          className="lazyload bunda-third-party__image"
                        />
                      </a>
                    );
                  } else {
                    logoLayout = (
                      <img
                        key={"third-party-logo" + index}
                        alt={
                          ThirdPartyLogoAltText && ThirdPartyLogoAltText.value
                            ? ThirdPartyLogoAltText.value
                            : "third-party-logo" + index
                        }
                        src={defaultImg}
                        data-src={ThirdPartyLogo.value}
                        className="lazyload bunda-third-party__image"
                      />
                    );
                  }
                }
                return logoLayout;
              })}
            </div>
          </div>
        </div>
      </BaseComponent>
    );
  }

  return bundaThirdPartyLayout;
};

export default FeatureGenericContentBundaThirdPartyList;
