import React from 'react';
import defaultImg from "../../../../assets/image/gray.png";

const FeatureMapsBundaLocationMap = (props) => {
  const {
    MapDetailsImageUrl = null,
    MapDetailsImageAltText = null,
    MapImageUrl = null,
    MapImageAltText = null
  } = props.fields || {};

  if (MapDetailsImageUrl && MapDetailsImageUrl.value &&
    MapImageUrl && MapImageUrl.value) {

    return (
      <div className="bunda-location-map">
        <div className="bunda-location-map__details">
          <img
            alt={
              MapDetailsImageAltText && MapDetailsImageAltText.value
                ? MapDetailsImageAltText.value
                : "bunda-location-map-details"
            }
            src={defaultImg}
            data-src={MapDetailsImageUrl.value}
            className="lazyload"
          />
        </div>
        <div className="bunda-location-map__loc">
          <img
            alt={
              MapImageAltText && MapImageAltText.value
                ? MapImageAltText.value
                : "bunda-location-map-loc"
            }
            src={defaultImg}
            data-src={MapImageUrl.value}
            className="lazyload"
          />
        </div>
      </div>
    )
  }
  else {
    return null;
  }

};

export default FeatureMapsBundaLocationMap;
