import React from "react";
import { Link, RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import defaultImg from "../../../../assets/image/gray.png";
import { getBgColor } from "../../../../helper/Color";

const FeatureGenericContentDiscoverSpaces = (props) => {
  const {
    Title = null,
    Description = null,
    RoomTypes = null,
    BackgroundColor = null,
    CTALink = null,
  } = props.fields || {};

  if (RoomTypes && RoomTypes.length) {
    return (
      <div className={`discover-spaces-wrapper ${getBgColor(BackgroundColor)}`}>
        <div className="container">
          {Title && Title.value && (
            <div className="module-title">
              <Text field={Title} />
            </div>
          )}

          {Description && Description.value && (
            <div className="module-subtitle">
              <RichText field={Description} />
            </div>
          )}

          <div className="item-space-wrapper">
            <div className="row">
              {RoomTypes.map((item, index) => {
                const {
                  RoomTypeImage = null,
                  RoomTypeImageAltText = null,
                  RoomTypeName = null,
                  RoomTypeDescription = null,
                } = item.fields || {};
                return (
                  <div
                    className="item-space col-12 col-lg-4"
                    key={`ItemSpace${index}`}
                  >
                    <div className="item-container">
                      <div className="item-image">
                        {RoomTypeImage && RoomTypeImage.value ? (
                          <img
                            alt={
                              RoomTypeImageAltText && RoomTypeImageAltText.value
                                ? RoomTypeImageAltText.value
                                : ""
                            }
                            src={defaultImg}
                            data-src={RoomTypeImage.value}
                            className="lazyload"
                          />
                        ) : (
                          <img src={defaultImg} alt="No image" />
                        )}
                      </div>
                      <div className="item-details">
                        <div className="item-name card-name">
                          <Text field={RoomTypeName} />
                        </div>
                        <div className="item-description card-description">
                          <Text field={RoomTypeDescription} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {CTALink &&
            CTALink.value &&
            CTALink.value.href &&
            CTALink.value.text && (
              <div className="cta-link">
                <Link
                  field={CTALink}
                  className="btn btn--small btn--light-primary"
                />
              </div>
            )}
        </div>
      </div>
    );
  } else return null;
};

export default FeatureGenericContentDiscoverSpaces;
